<template>
  <div class="dashboard-org">
    <Header></Header>

    <v-container v-if="!isLoading" class="my-3">
      <v-row>
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems"></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <h1>Organization Reports</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="primary" dark>Reports</v-toolbar>
            <v-card-text>
              <v-data-table
                :loading="dataLoading"
                loading-text="Loading... Please wait"
                item-key="id"
                :headers="headers"
                :items="items"
                single-expand
                :options.sync="options"
                :server-items-length="itemsTotal"
                class="elevation-5 mb-15 align-center"
                :footer-props="{
                  itemsPerPageOptions: [10]
                }"
              >
              <template v-slot:item.action="{item}">
                  <v-btn class="" color="primary" @click="goToUrl('reviewerdashboardorg', item.organization_id)" rounded>
                  View Reports
                </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import ReviewersService from "../services/reviewers.service";

export default {
  name: "ManageCategories",
  components: {
    Header
  },
  data() {
    return {
      search: "",
      breadcrumbsItems: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Organization Reports",
          disabled: true,
          href: "/orgreports"
        }
      ],
      editedItem: {
        name: "",
        description: ""
      },
      originalItem: {
        name: "",
        description: ""
      },
      defaultlItem: {
        name: "",
        description: ""
      },
      items: [],
      isLoading: false,
      snackbar: false,
      valid: true,
      alert: "",
      dataLoading: true,
      options: {},
      itemsTotal: 0,
      currentPage: 1,
      lastPage: null,
      headers: [
        { text: "Organization", value: "organization.company" },
        {
          text: "",
          value: "action",
          sortable: false,
          filterable: false,
          width: "150px"
        }
      ],
      editedIndex: -1
    };
  },

  watch: {
    options: {
      async handler() {
        if (this.lastPage !== null) {
          this.getItems();
        }
      },
      deep: true
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  methods: {

    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },
    async initialize() {
      this.getItems();
    },

    async getItems() {
      this.dataLoading = true;
      const { page } = this.options;

      const response = await ReviewersService.getReviewOrgs({
        query: {
          page
        }
      });

      this.options.itemsPerPage = parseInt(response.data.meta.per_page);
      this.items = response.data.data.slice();
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      this.dataLoading = false;
    },
    goToUrl(myUrl, id) {
      this.$router.push({
        path: "/" + myUrl + "/" + id
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.full-width-buttons button.v-btn {
  width: 100% !important;
}
.disabled_check.v-icon {
  color: rgba(0, 0, 0, 0.14);
}
.back-to-blue {
  background-color: #C5E1E9 !important;
  color: #2F91AE;
}
.fix-detail-dashboard {
  @media screen and (min-width: 767px) {
    padding-left: 116px;
  }
  margin-top: -2px;
  padding-top: 2px;
  box-shadow: none !important;
}
</style>
